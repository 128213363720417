.create-profile-container {
    background: var(--white);
}


.personal-details-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 900px;
    text-align: center;
    margin: auto;
}

.personal-main-title {
    margin-bottom: 16px;
    max-width: 600px;
}

.personal-details-body {
    margin-top: 5%;
}

.personal-details-body form .ant-select {
    margin: 0 auto;
}

.personal-details-body .mt {
    margin-top: 1rem;
}

.personal-details-body .big-mt {
    margin-top: 3rem;
}

.personal-box {
    padding: 1rem;
    width: 100%;
}

.personal-details-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4.5rem;
    margin-bottom: 84px;
}

@media screen and (min-width: 768px) {
    .personal-details-btn {
        margin-top: 6rem;
    }
}