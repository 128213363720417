.header-container {
  position: sticky;
  display: flex;
  padding: 24px;
  background-color: white;
}

.header-logo {
  flex: 1;
}

.header-logo img {
  height: 30px;
  width: 117px;
}

.header-and-progress {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}

.questions-container {
  margin-top: 12.98rem;
}
