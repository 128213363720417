.app-dashboard {
  width: 100vw;
  height: 100vh;
  min-height: 500px;
  display: flex;
}

.left-menu {
  flex-shrink: 1;
  width: 250px;
  height: 100%;
  background-color: var(--white);
  border-right: 1px solid var(--gray-20);
}

.app-workspace {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.app-navbar {
  flex-shrink: 1;
  width: 100%;
  height: 70px;
}

.workspace {
  flex: 1;
  /* background: rgb(49, 49, 1); */
  overflow-y: auto;
}

.mobile-bottom-nav {
  display: none !important;
}

@media screen and (max-width: 699px) {
  html * {
    scroll-behavior: smooth;
  }

  .left-menu,
  .app-navbar {
    display: none !important;
  }

  .centroid {
    padding: 0 15px;
  }

  .app-workspace {
    width: 100%;
    height: calc(100vh - 60px);
    position: absolute;
    top: 0;
    left: 0;
    padding: 30px 0;
    padding-bottom: 7px;
    scroll-behavior: smooth;
  }

  .mobile-bottom-nav {
    width: 100%;
    height: 60px;
    display: flex !important;
    justify-content: space-between;
    background-color: var(--white);
    box-shadow: var(--paper-shadow);
    padding: 12px;
    position: fixed;
    bottom: 0;
    left: 0;
  }

  .mobile-link {
    flex: 1;
    display: grid;
    place-items: center;
  }

  .mobile-link img {
    height: 28px;
  }

  .mobile-link .mobile-app {
    height: 24px;
  }
}
