:root {
  --white: #ffffff;
  --white-card: #ffffff;
  /* blue shades */
  --blue-00: #e8f2ff;
  --blue-10: #b5d7ff;
  --blue-20: #80b9ff;
  --blue-30: #4f9cff;
  --blue-40: #237fff;
  --blue-50: #0065f3;
  --blue-60: #0968e3;
  --blue-70: #0e56b8;
  --blue-80: #113c74;
  --blue-90: #0f2a4d;

  /* gray shades */
  --gray-00: #fafbfc;
  --gray-10: #f3f4f5;
  --gray-20: #e1e4e8;
  --gray-30: #d1d5da;
  --gray-40: #959da5;
  --gray-50: #88929e;
  --gray-60: #717a85;
  --gray-70: #586069;
  --gray-80: #2f363d;
  --gray-90: #191c1f;

  /* red shades */
  --red-00: #ffeef0;
  --red-10: #ffdce0;
  --red-20: #fdaeb7;
  --red-30: #f97583;
  --red-40: #ea4a5a;
  --red-50: #d73a49;
  --red-60: #cb2431;
  --red-70: #b31d28;
  --red-80: #9e1c23;
  --red-90: #86181d;

  /* green shades */
  --green-50: #28A745;

  --card-shadow: 0px 5px 22px rgba(0, 0, 0, 0.3);
  --paper-shadow: 0px 3px 6px 0px #191c1f0f, 0px 1px 2px 0px #191c1f0f,
    0px -1px 4px 0px #191c1f0a;
}

body,
html {
  background-color: var(--gray-10) !important;
  cursor: default;
}

.flex {
  display: flex;
}

.centroid {
  width: 100%;
  padding: 0 30px;
}

.centroid-500 {
  max-width: 500px;
  margin: auto;
}

.hidebx {
  display: none;
}

.app-paper {
  padding: 24px;
  background-color: var(--white);
  box-shadow: var(--paper-shadow);
  border-radius: 10px;
}

.ant-checkbox-inner {
  width: 22px !important;
  height: 22px !important;
  border-color: var(--gray-40) !important;
}

.ant-checkbox-inner::after {
  transform: rotate(45deg) scale(1.1) translate(-30%, -70%) !important;
  -webkit-transform: rotate(45deg) scale(1.1) translate(-30%, -70%) !important;
}

.preloader {
  width: 30px;
  height: 30px;
  animation: 1s rotateCircle linear;
  -webkit-animation: 1s rotateCircle linear infinite;
}

.preloader img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.isDisabled {
  opacity: 0.4 !important;
  pointer-events: none !important;
  cursor: no-drop;
}

.margin-top-20:not(:first-of-type) {
  margin-top: 4rem;
}

@keyframes rotateCircle {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

.btn-spinner {
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-top: none;
  border-left: none;
  animation: 1s rotateCircle infinite linear;
  border-radius: 50%;
  -webkit-animation: 1s rotateCircle infinite linear;
  margin: auto;
  display: table;
}

.ant-progress-line {
  z-index: 99;
}

.logged-routes-spinner {
  display: flex;
  align-items: center;
  height: 100vh;
}