.preloader-box {
  width: 100%;
  max-width: 500px;
  height: 100vh;
  display: grid;
  place-items: center;
  text-align: center;
  margin: auto;
}

.spinner {
  width: 50px;
  height: 50px;
  margin: auto;
  margin-bottom: 20px;
  background: conic-gradient(from 90deg at 50% 50%, rgba(0, 175, 170, 0.0001) 0deg, #237FFF 359.96deg, rgba(0, 175, 170, 0.0001) 360deg);
  border-radius: 50%;
  animation: 1s rotateCircle infinite linear;
  -webkit-animation: 1s rotateCircle infinite linear;
  position: relative;
}

.spinner.small {
  width: 24px;
  height: 24px;
}

.spinner-inner {
  border-radius: 50%;
  background-color: white;
  position: absolute;
  left: 1rem;
  right: 1rem;
  top: 1rem;
  bottom: 1rem;
}

.spinner-inner.small {
  left: 5px;
  right: 5px;
  top: 5px;
  bottom: 5px;
}

@keyframes rotateCircle {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}