.app-tab-nav {
  gap: 15px;
  border-bottom: 1px solid var(--gray-20);
}

.app-tab-item {
  padding: 12px 0;
  cursor: pointer;
}

.tab-nav-active {
  color: var(--blue-60);
  border-bottom: 2px solid var(--blue-60);
}

.documents-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 24px 0;
  gap: 24px;
}

.isMobileDoc {
  display: none;
}

/* === DOC UPLOAD CARD === */
.docupload-box {
  text-align: center;
  min-width: 232px;
}

.docupload-icon {
  width: 60px;
  height: 60px;
  margin: auto;
  margin-bottom: 24px;
}

.docupload-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.docupload-infobx {
  height: 180px;
  flex-direction: column;
  gap: 12px;
}

.docupload-summary {
  flex: 1;
}

.btn-uploaded {
  padding: 6px 18px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  color: var(--white);
  background-color: var(--blue-60);
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  background-repeat: no-repeat;
  background-position: 10px;
  background-size: 17px;
  border: 1px solid var(--blue-60);
  font-weight: bold;
}

.btn-uploaded span {
  padding-right: 6px;
}

.btn-uploaded img {
  width: 15px;
  height: 15px;
}

.doc-btn-holder .border-btn {
  width: 100%;
}

/* MY DOCUMENTS */
.mydocuments-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 24px 0px;
  gap: 24px;
}

.doc-card {
  padding: 0 !important;
  min-height: 224px;
}

.doc-preview {
  height: 154px;
  overflow: hidden;
  background: var(--gray-20);
  background-image: url(../assets/pdf.svg);
  background-size: 52px;
  background-position: center;
  background-repeat: no-repeat;
}

.blank-doc-preview {
  height: 154px;
  overflow: hidden;
  background: var(--gray-20);
  background-image: url(../assets/blank_doc.svg);
  background-size: 52px;
  background-position: center;
  background-repeat: no-repeat;
}

.doc-footer {
  flex-direction: column;
  gap: 5px;
  border-top: 1px solid var(--gray-20);
  padding: 7px 16px;
}

.doc-footer-stamps {
  gap: 7px;
  justify-content: space-between;
  position: relative;
}

.doc-card-timestamps {
  flex: 1;
  font-size: 11px;
  opacity: 0.5;
}

.doc-card-dots {
  cursor: pointer;
}

.doc-mobile-checked {
  display: none;
}

.download-btn {
  width: 150px;
  padding: 6px 12px;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  position: absolute;
  right: -5px;
  bottom: -48px;
  background-color: var(--white);
  transition: 0.3 ease-in-out;
  cursor: pointer;
}

.download-btn:hover {
  background-color: var(--gray-10);
}

.doc-mobile-pdf {
  display: none;
}

/* empty */
.mydocuments-empty {
  width: 100%;
  height: 75vh;
  background-image: url(../assets/empy-docs-page.svg);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
}

@media screen and (max-width: 1440px) {
  .mydocuments-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 1300px) {
  .documents-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1205px) {
  .mydocuments-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 799px) {
  .documents-grid,
  .mydocuments-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  /* .app-tab-nav {
    gap: 15px;
    border-bottom: 1px solid var(--gray-20);
  } */
}

@media screen and (max-width: 699px) {
  .application-apply .H4 {
    font-size: 24px;
  }

  .isMobileDoc {
    display: block;
  }

  .app-tab-nav {
    border: none;
  }

  .app-tab-item {
    display: flex;
    align-items: center;
    transition: 0.3s ease;
    -webkit-transition: 0.3s ease;
  }

  .app-tab-item > * {
    font-size: 14px;
  }

  .tab-nav-active {
    padding: 8px 16px;
    background-color: var(--white);
    border: none;
    box-shadow: var(--paper-shadow);
    border-radius: 10px;
    color: var(--gray-90);
  }

  /* === Documents Grid ===  */
  .documents-grid {
    display: flex;
    flex-direction: column;
  }

  .docupload-box {
    display: flex;
    gap: 15px;
    padding-left: 70px !important;
    background-image: url(../assets/mobile-doc.svg);
    background-position: 24px;
    background-size: 32px;
    background-repeat: no-repeat;
  }

  .docupload-icon {
    width: 32px;
    height: 32px;
    margin: 0;
    display: none;
  }

  .doc-mobile-hide {
    display: none;
  }

  .doc-mobile-pdf {
    display: block;
  }

  .doc-mobile-checked {
    width: 24px;
    display: block;
  }

  .docupload-infobx {
    flex: 1;
    height: unset;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }

  .docupload-title {
    font-size: 14px;
    font-weight: 700;
  }

  .docupload-summary {
    display: none;
  }

  .doc-btn-holder .border-btn {
    min-width: 70px;
    padding: 4px 12px;
  }

  .doc-btn-holder .Bold {
    font-size: 14px;
  }

  /* === Document uploaded === */
  .mydocuments-grid {
    display: flex;
    flex-direction: column;
  }

  .doc-card {
    min-height: unset;
    display: flex;
    padding: 8px 0;
  }

  .doc-mobile-pdf {
    width: 24px;
    margin-left: 12px;
  }

  .doc-preview {
    display: none;
  }

  .doc-footer {
    flex: 1;
    border: none;
  }

  .doc-footer-stamps img:nth-child(1) {
    display: none;
  }

  .doc-card-dots {
    width: 24px;
    transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
  }

  .download-btn {
    bottom: -28px;
  }
}

.btn-doc-upload {
  margin: auto;
  display: table;
  padding: 12px 20px;
  text-transform: uppercase;
  background: var(--blue-00);
  color: var(--blue-60);
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
}

.btn-doc-upload input {
  display: none;
}
