/* hide in mobile and medium screen */
@media screen and (max-width: 1000px) {
    .ads-banner {
        display: none !important;
    }
}

.ads-banner {
    width: 312px;
    height: 111px;
    z-index: 90;
    position: absolute;
    top: 2rem;
    left: 50%;
    /* half of width to horizontally center the banner */
    margin-left: -156px;
    display: grid;
    grid-template-columns: 0.35fr 0.55fr;
}

.ads-banner img.banner {
    width: 312px;
    height: 111px;
    top: 1rem;
    margin-top: -0.5rem;
    margin-left: -0.5rem;
}

.ads-banner.top-zero {
    top: 0;
}