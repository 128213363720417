.journey-navline {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  align-items: center;
}

.financial-pages {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background: var(--white);
}

.financial-pages .mt {
  margin-top: 1rem;
}

.financial-pages .continue-btn {
  text-align: center;
}

.isTextBlue {
  color: var(--blue-50);
}

.financial-page1-description {
  max-width: 756px;
  margin: auto;
  padding-bottom: 30px;
}

.financial-page1-footer {
  padding-top: 30px;
}

.financial-page1-footer a {
  text-decoration: underline;
}

.financial-experian-box {
  width: 262px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 42px;
  padding: 20px 0;
}

.btn-connect-experian,
.btn-manual-info {
  border: none;
  padding: 10px 20px;
  background: rgba(42, 74, 136, 1);
  color: var(--white);
  border-radius: 10px;
  cursor: pointer;
}

.btn-manual-info {
  background: transparent;
  border: 1px solid var(--gray-40);
  color: initial;
}

.central-holder {
  width: 100%;
  max-width: 500px;
  margin: auto;
  padding: 50px 1rem;
}

.central-holder .H4 {
  text-align: center;
  margin-bottom: 2rem;
}

@media screen and (min-width: 768px) {
  .central-holder .H4 {
    margin-bottom: 6rem;
  }
}

.central-form-group .continue-btn {
  margin-top: 4.5rem;
}

@media screen and (min-width: 768px) {
  .central-form-group .continue-btn {
    margin-top: 6rem;
  }
}

.central-form-group {
  gap: 10px;
  width: 100%;
  margin: 0 auto;
}

.central-form-group .mb {
  margin-bottom: 1rem;
}

.central-form-group .ant-select {
  max-width: 460px;
  margin: 0 auto;
  margin-top: 1rem;
}

.central-form-group .input-wrapper {
  margin: 0 auto;
}

.central-form-group .ant-select-selector {
  text-align: left;
}

.central-form-group .border-btn {
  margin: auto;
}

.financial-add-another {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--blue-50);
  cursor: pointer;
}

.fieldset-holder {
  position: relative;
}

.remove-fieldset {
  position: absolute;
  top: 22px;
  right: -50px;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid var(--gray-20);
  background-image: url(../assets/close.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px;
  cursor: pointer;
}