.question-holder-content {
  max-width: 360px;
  width: 100%;
}

.question-holder-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 9%;
}

.question-holder-title {
  margin-bottom: 48px;
}

.back-btn {
  width: 56px;
  height: 56px;
  border-radius: 10px;
  background-color: #fff !important;
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
  position: absolute;
  top: 50px;
  left: 20px;
  background-image: url(/src/assets/carret-left.svg);
  background-position: 50%;
  background-size: 10px;
  background-repeat: no-repeat;
  cursor: pointer;
}
