.nabvar-notify-alert {
  position: relative;
}

.nabvar-notify-alert::before {
  content: '';
  width: 14px;
  height: 14px;
  background-color: var(--red-40);
  position: absolute;
  top: -2px;
  right: -2px;
  border-radius: 50%;
}

.notification-bag {
  width: 372px;
  height: 506px;
  border-radius: 10px;
  position: absolute;
  top: 50px;
  right: -70px;
  z-index: 5;
  padding: 26px 24px;
  background-color: var(--white);
  box-shadow: var(--card-shadow);
  display: flex;
  flex-direction: column;
}

.notification-bag::before {
  content: '';
  width: 20px;
  height: 20px;
  background-color: var(--white);
  position: absolute;
  top: -5px;
  right: 80px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.notification-header {
  flex-shrink: 0;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--gray-30);
}

.notification-content {
  flex: 1;
  padding: 10px 0;
  overflow-y: auto;
}

/* notification tile */
.notification-tile {
  padding: 18px 7px;
  gap: 12px;
  border-bottom: 1px solid var(--gray-20);
  margin-bottom: 5px;
  border-radius: 10px;
}

.notification-tile:hover {
  background-color: var(--gray-10);
}

.notify-unread-back,
.notify-unread-back:hover {
  background-color: var(--blue-00);
}

.notification-icon {
  flex-shrink: 0;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: var(--gray-20);
  display: grid;
  place-items: center;
}

.notify-unread-back .notification-icon {
  background-color: var(--blue-10);
}

.notification-details {
  flex: 1;
}

.notify-read-receipt {
  width: 20px;
  height: 20px;
  display: grid;
  place-items: center;
}

.notify-read-receipt img {
  width: 16px;
  height: 16px;
  object-fit: contain;
  display: none;
}

.notify-unread-back .notify-read-receipt img,
.notification-tile:hover .notify-read-receipt img {
  display: block;
}

.notification-date {
  margin-top: 12px;
  margin-bottom: 12px;
}

.notification-mobile .notification-tile {
  background-color: var(--white);
  border-radius: unset;
  border: none;
  margin-bottom: 0;
}

.notification-mobile .notification-icon {
  background-color: var(--gray-30);
}

.notification-group .notification-tile:first-child {
  border-radius: 10px 10px 0 0;
}

.notification-group .notification-tile:last-child {
  border-radius: 0 0 10px 10px;
}
