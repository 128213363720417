.add-another-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
}

.personal-container {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    background: var(--white);
}