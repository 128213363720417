.H1 {
  font-size: 86px;
  line-height: 94px;
  letter-spacing: -1.5px;
  margin: 0;
}

@media screen and (min-width: 768px) {
  .H1 {
    font-size: 96px;
    line-height: 104px;
  }
}

.H2 {
  font-size: 50px;
  line-height: 62px;
  letter-spacing: -0.5px;
  margin: 0;
}

@media screen and (min-width: 768px) {
  .H2 {
    font-size: 60px;
    line-height: 72px;
  }
}

.H3 {
  font-size: 38px;
  line-height: 44px;
  letter-spacing: 0px;
  margin: 0;
}

@media screen and (min-width: 768px) {
  .H3 {
    font-size: 48px;
    line-height: 54px;
  }
}

.H4 {
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0px;
  margin: 0;
}

@media screen and (min-width: 768px) {
  .H4 {
    font-size: 34px;
    line-height: 40px;
  }
}

.H5 {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0px;
  margin: 0;
}

@media screen and (min-width: 768px) {
  .H5 {
    font-size: 28px;
    line-height: 34px;
  }
}

.H6 {
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0px;
  margin: 0;
}

.SubTitle {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0px;
}

.SmallSubTitle {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0px;
}

.Bold {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.15px;
}

@media screen and (min-width: 768px) {
  .Bold {
    font-size: 16px;
    line-height: 24px;
  }
}

.SmallBold {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
}

.Caption {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.Overline {
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 1.5px;
}