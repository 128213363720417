.phone-input-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.ant-select-dropdown {
  border: 1px solid #E1E4E8;
  margin-top: 35px;
  margin-left: -18px;
  border-radius: 10px !important;
}


.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 !important;
}

.ant-select {
  display: flex !important;
}

.ant-select-arrow {
  padding-right: 22px;
  right: 0;
}

.phone-input-country-code-wrapper {
  width: 132px;
  height: 65px;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--gray-20);
  border-radius: 10px;
  background-color: var(--white);
  margin-right: 12px;
  padding: 8px 12px 12px;
}

@media screen and (max-width: 699px) {
  .phone-input-country-code-wrapper {
    display: none;
  }
}
