.left-menu {
  position: relative;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
}

.app-logo {
  padding: 34px;
}

.app-logo img {
  height: 30px;
  width: 117px;
}

.isDesktop {
  display: block;
}
.isTablet {
  display: none;
}

/* menu list */
.menu-items-list {
  padding-top: 130px;
}

.menu-item {
  display: flex;
  gap: 7px;
  margin-bottom: 25px;
  padding: 0 34px;
  text-decoration: none;
  color: initial;
}

.menu-item img {
  filter: grayscale(10);
  -webkit-filter: grayscale(10);
}

.menu-item:hover .menu-logo img {
  filter: grayscale(0) !important;
  -webkit-filter: grayscale(0) !important;
}

.menu-item:hover {
  color: var(--blue-60);
}

.menu-logo img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.menu-item-selected {
  position: relative;
  color: var(--blue-60);
}

.menu-item-selected img {
  filter: grayscale(0) !important;
}

.menu-item-selected:after {
  content: '';
  width: 2px;
  height: 36px;
  background-color: var(--blue-60);
  position: absolute;
  right: 0;
  top: -7px;
  border-radius: 10px 0 0 10px;
}

/* ===  LANGUAGE SELECTION === */
.lang-select-box {
  width: 100%;
  /* padding: 14px 10px; */
  /* background-color: var(--blue-20); */
  position: absolute;
  bottom: 20px;
  padding: 0 34px;
}

.lang-select {
  width: 100%;
  position: relative;
}

.lang-select-inner,
.lang-option {
  width: 100%;
  gap: 15px;
  padding: 14px 10px;
  align-items: center;
  cursor: pointer;
}

.lang-flag {
  width: 30px;
  height: 30px;
  background-color: var(--gray-10);
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid var(--gray-20);
}

.lang-flag img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.lang-options-box {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 68px;
  background-color: var(--white);
  box-shadow: var(--card-shadow);
  overflow: hidden;
  border-radius: 3px;
}

.lang-option:hover {
  background-color: var(--gray-10);
}

.lang-select-inner .lang-option:hover {
  background-color: var(--white);
}

/* ===  END OF LANGUAGE SELECTION === */

@media screen and (max-width: 1205px) {
  .left-menu {
    width: 70px !important;
  }

  .isDesktop {
    display: none;
  }

  .isTablet {
    display: block;
  }

  .app-logo {
    padding: 14px;
  }

  .app-logo img {
    height: 40px;
  }

  .menu-item {
    padding: 0 24px;
  }

  .lang-select-box {
    padding: 0;
  }

  .menu-text,
  .lang-text {
    display: none;
  }

  .lang-options-box {
    width: 80% !important;
    left: 5px;
  }

  .lang-select-inner {
    padding: 0 !important;
  }

  .lang-select-box .navbar-caret {
    transform: translateX(-20px);
  }

  .lang-select-box .rotate-180 {
    transform: translateX(-20px) rotate(180deg) !important;
    -webkit-transform: translateX(-20px) rotate(180deg) !important;
  }
}

@media screen and (max-width: 699px) {
  .menu-text,
  .lang-text {
    display: block;
  }
  .isMobile .lang-select-box {
    position: unset;
    margin-top: 24px;
  }
  .isMobile .lang-options-box {
    display: flex;
    flex-direction: column-reverse;
    position: unset;
    width: 100% !important;
    height: 192px;
    border-radius: 10px;
  }
  .isMobile .lang-select-inner .lang-option {
    background: var(--blue-00);
    border-radius: 10px;
    margin: 4px 4px 0 4px;
  }
}
