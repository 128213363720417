.login-auth {
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
}

.login-holder {
  width: 100%;
  max-width: 400px;
  padding: 0 1rem;
}

.login-paper {
  padding-top: 50px;
  padding-bottom: 50px;
  margin-bottom: 16px;
}

.login-app-logo {
  height: 30px;
  display: table;
  margin: auto;
}

.login-app-logo img {
  height: 30px;
}

.btn-submit-loader {
  border: none;
  cursor: pointer;
  width: 100%;
  background-color: var(--blue-60);
  color: var(--white);
  padding: 13px;
  border-radius: 10px;
}


.btn-submit-loader span {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.15px;
}

.auth-other-paper {
  padding-top: 15px;
  align-items: flex-end;
  justify-content: space-between;
}

.auth-link {
  color: var(--blue-60);
}

.auth-link:hover {
  color: var(--blue-60);
  text-decoration: underline;
}

.auth-login-footer {
  text-align: center;
}

.auth-link-bottom {
  padding-top: 10px;
}
