.step-content-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 0 24px;
    margin-top: 29px;
    margin-bottom: 29px;
    position: relative;
}

.create-profile-container .step-content-header {
    padding: 29px 0 0 24px;
    margin-top: -1rem;
    position: fixed;
    right: 0;
    left: 0;
    background: white;
}

.previous-btn-container {
    flex: 1;
}

.previous-btn {
    width: 1rem;
    height: 1rem;
    background: var(--white);
    border: none;
    background-image: url(assets/carret-left.svg);
    background-position: 50%;
    background-repeat: no-repeat;
    cursor: pointer;
    background-size: contain;
}

@media screen and (min-width: 768px) {
    .previous-btn {
        width: 56px;
        height: 56px;
        background: var(--white);
        border: 1px solid var(--gray-20);
        border-radius: 10px;
        background-image: url(assets/carret-left.svg);
        background-position: 50%;
        background-size: 10px;
        background-repeat: no-repeat;
        cursor: pointer;
    }
}

.step-content-body {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10rem;
    padding: 0 1rem;
}

.step-content-body .ant-select {
    margin: 0 auto;
}

.step-content-body-container {
    width: 100%;
    max-width: 37.5rem;
}

.close-btn {
    background: var(--white);
    border: none;
    background-position: 50%;
    background-repeat: no-repeat;
    cursor: pointer;
    padding: 1rem;
    margin-right: 1rem;
}

@media screen and (min-width: 768px) {
    .close-btn {
        background: var(--white);
        border: 1px solid var(--gray-20);
        border-radius: 10px;
        background-position: 50%;
        background-size: 10px;
        background-repeat: no-repeat;
        cursor: pointer;
    }
}

.step-content-container {
    overflow: hidden;
}