.input-wrapper {
  width: 100%;
  max-width: 460px;
  margin: 0 auto;
}

.input-group {
  position: relative;
  padding: 15px 0 0;
}

.select-group {
  position: relative;
}

.select-group .react-select-container,
.select-group .react-select__control {
  height: 56px;
  border-radius: 10px;
  border: 1px solid var(--gray-20);
}

.react-select-container .react-select__value-container {
  padding-left: 12px;
}

.react-select-container .react-select__indicator-separator {
  margin: 16px 0;
}

.react-select-container .react-select__indicator {
  padding: 0 16px;
}

.select-group .select-label {
  top: 4px;
  left: 1px;
  position: absolute;
  transition: 0.2s;
  font-size: 12px;
  color: var(--gray-60);
  padding-left: 12px;
}

.input {
  width: 100%;
  height: 56px;
  border: 2px solid var(--gray-20);
  border-radius: 10px;
  outline: none;
  padding-top: 10px;
  padding-left: 14px;
}

.input[type="date"]::-webkit-datetime-edit-text,
.input[type="date"]::-webkit-datetime-edit-month-field,
.input[type="date"]::-webkit-datetime-edit-day-field,
.input[type="date"]::-webkit-datetime-edit-year-field {
  text-transform: uppercase;
}

/* for checkbox */
.ant-checkbox-wrapper span {
  font-size: 1rem;
}

.input:focus {
  border-color: var(--blue-60);
  outline: 0;
  box-shadow: var(--paper-shadow);
}

.input::placeholder {
  color: transparent;
}

.input-label {
  position: absolute;
  top: 15px;
  display: block;
  transition: 0.2s;
  font-size: 12px;
  color: var(--gray-60);
  padding-left: 16px;
  margin-top: 4px;
}

.input:placeholder-shown~.input-label {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  top: 24px;
  color: var(--gray-60);
  cursor: text;
  pointer-events: none;
}

.input:focus~.input-label {
  position: absolute;
  top: 15px;
  display: block;
  transition: 0.2s;
  font-size: 12px;
  color: var(--gray-60);
  border-color: var(--blue-60);
}

.input-error {
  background-image: url(../assets/error-icon.svg);
  background-position: 0px;
  background-repeat: no-repeat;
  background-size: 16px;
  padding: 5px;
  padding-left: 20px;
  color: var(--red-50);
  font-size: 12px;
}

.input-box-error {
  border-color: var(--red-30) !important;
  background-color: var(--red-00) !important;
}

/* Password regex */
.input-group .input-regex-ui {
  width: 250px;
  position: absolute;
  top: 80px;
  right: 0;
  left: 0;
  margin: auto;
  background: var(--white);
  z-index: 10000;
  list-style-type: none;
  padding: 12px 10px;
  border: 1px solid var(--gray-20);
  border-radius: 10px;
}

.input-regex-ui li {
  padding-left: 30px;
  opacity: 0.5;
  background-image: url(../assets/small-check.svg);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: 7px;
}

.input-regex-ui li::before {
  content: '';
  width: 15px;
  height: 15px;
  background: var(--white);
  border: 1px solid var(--gray-20);
  border-right: none;
  border-bottom: none;
  position: absolute;
  top: -8px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 10;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.input-regex-ui-valid {
  opacity: 1 !important;
  background-image: url(../assets/small-check-green.svg) !important;
  color: #1d8e37;
}

/* End of Password regex */

/* phone Input Modify */

.phone-input {
  width: 232px;
  height: 64px;
  border: 1px solid var(--gray-20);
  border-radius: 10px;
  outline: none;
  padding-top: 10px;
  padding-left: 16px;
}

.phone-input::placeholder {
  color: transparent;
}

.phone-input:focus {
  border-color: var(--blue-60);
  outline: 0;
  box-shadow: var(--paper-shadow);
}

.phone-input-label {
  position: absolute;
  top: 89px;
  display: block;
  transition: 0.2s;
  font-size: 12px;
  color: var(--gray-60);
  padding-left: 16px;
  margin-top: 4px;
}

.phone-input:placeholder-shown~.phone-input-label {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  top: 113px;
  color: var(--gray-60);
  cursor: text;
}

.phone-input:focus~.phone-input-label {
  position: absolute;
  top: 93px;
  display: block;
  transition: 0.2s;
  font-size: 12px;
  color: var(--gray-60);
}

/* number Input Modify */

.ant-input-number {
  width: 460px !important;
  height: 64px !important;
  border: 1px solid var(--gray-20) !important;
  border-radius: 10px !important;
  outline: none;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
}

.ant-input-number-input {
  height: 64px !important;
}

.ant-input-number-handler-wrap {
  display: none !important;
}

.ant-input-number:focus,
.ant-input-number-focused {
  border-color: var(--blue-60) !important;
  outline: 0;
  box-shadow: var(--paper-shadow) !important;
}

.form {
  margin: 0 auto;
  width: 100%;
}

/* phone Input Modify */

/* Mobile Styles */

@media screen and (max-width: 699px) {
  .phone-input {
    width: 343px;
    height: 56px;
  }

  .phone-input-label {
    top: 130px;
    margin-top: 9px;
  }

  .phone-input:placeholder-shown~.phone-input-label {
    top: 149px;
    margin-top: 0;
  }

  .phone-input:focus~.phone-input-label {
    top: 130px;
    margin-top: 9px;
  }
}

/* End of Mobile Styles */

.ant-select-selector {
  height: 56px !important;
  border-radius: 10px !important;
  border: 2px solid var(--gray-20) !important;
  font-size: 16px;
}

.ant-select {
  display: unset !important;
  max-width: 460px;
}

.ant-select-selection-placeholder {
  margin: 0 4px 0 11px;
  color: var(--gray-60) !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  margin: 12px 4px 0 11px;
}

.ant-select-arrow {
  color: var(--gray-60) !important;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.close-btn-input {
  background: transparent;
  border: none;
}

.input-inner {
  display: flex;
}

.ant-input-number-input {
  padding-top: 10px !important;
  padding-left: 12px !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
  top: 12px !important;
}

.currency-tag-input {
  font-weight: 700;
  font-size: 16px;
  position: absolute;
  top: 36px;
  left: 13px;
}

.input-number {
  width: 100%;
  height: 56px;
  border: 2px solid var(--gray-20);
  border-radius: 10px;
  outline: none;
  padding-top: 10px;
  padding-left: 22px;
  font-size: 16px;
}

.percentage-input {
  padding-left: 2rem;
}

.currency-input {
  padding-left: 1.5rem;
}