.stages-page {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  transition: 300ms ease;
  -webkit-transition: 300ms ease;
}

.btnntest {
  display: grid;
  justify-items: center;
}

.stages-content {
  padding: 15px;
  padding-top: 60px;
  width: 95%;
  max-width: 806px;
  margin: auto;
}

/* Stages Capsule */
.stage-capsule {
  width: 100%;
  padding: 30px;
  background: var(--white);
  box-shadow: var(--paper-shadow);
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  transition: 300ms ease;
  -webkit-transition: 300ms ease;
}

.stage-capsule a {
  color: initial;
  display: flex;
}

.stage-capsule-left {
  gap: 15px;
  align-self: center;
  display: flex;
}

.stage-capsule-left img {
  width: 28px;
}

.apply-capsule-grayscale {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

.btn-stage-capsule, .bg-stage-capsule {
  min-width: 126px;
  padding: 7px 18px;
  border: 1px solid var(--gray-40);
  cursor: pointer;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  background: transparent;
  transition: 300ms linear;
  -webkit-transition: 300ms linear;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  70% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.btn-pulse {
  animation: pulse 1.5s infinite;
  -webkit-animation: pulse 1.5s infinite;
  background-color: var(--blue-50);
  color: var(--white);
  border: none;
}

.submit-capsule {
  top: -15px;
  bottom: -15px;
}

.btn-stage-completed {
  background: var(--green-50);
  color: var(--white);
  border: 1px solid var(--green-50);
}

.btn-stage-completed span:last-child {
  opacity: 0;
  display: none;
}

.btn-stage-completed:hover span:first-child {
  opacity: 0;
  display: none;
}

.btn-stage-completed:hover span:last-child {
  display: block;
  opacity: 1;
}

.H6 {
  transition: 300ms ease;
}

/* End of Stages Capsule */

@media screen and (max-width: 599px) {
  .stage-capsule {
    padding: 15px;
  }

  .stages-content {
    padding: 0;
    padding-top: 30px;
  }

  .stage-capsule-left-title .H6 {
    font-size: 18px;
  }

  .stage-capsule-right .btn-stage-capsule {
    font-size: 14px;
    min-width: unset !important;
  }
}