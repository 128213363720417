.offer-on-borading-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 15px;
}

.offer-on-borading-wrapper .SubTitle {
  max-width: 644px;
  margin: auto;
  padding: 40px 0;
  padding-bottom: 0;
}

.offer-container {
  width: 100%;
  height: 100vh;
  background: var(--white);
  overflow: hidden;
  position: relative;
}

.user-journey-pages .ant-progress-inner {
  position: fixed !important;
  left: 0 !important;
  top: 0 !important;
}

.user-journey-pages .ant-progress-bg {
  background: var(--blue-40) !important;
}

.offer-on-borading-btn {
  margin-top: 10%;
}

.offer-question-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px;
  margin-top: 4.5rem;
}

.offer-question-content {
  text-align: center;
  max-width: 900px;
  margin-bottom: 6.5rem;
}

.offer-question-tooltip {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 34px;
}

.offer-buttons {
  width: 100%;
  max-width: 37.25rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  justify-items: center;
}

/* OFFERS TABLE */
.offers-table-box {
  width: 98%;
  max-width: 1400px;
  margin: auto;
  margin-top: 20px;
}

.offers-params {
  background: var(--white);
  padding: 30px;
  text-align: center;
  border-radius: 10px;
  box-shadow: var(--paper-shadow);
  margin-bottom: 20px;
}

.btn-offers-params {
  border: none;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  font-weight: 600;
  background: var(--blue-50);
  color: var(--white);
  cursor: pointer;
  border: 2px solid var(--blue-00);
}

.offers-params-form {
  gap: 15px;
  -webkit-gap: 15px;
  padding-top: 20px;
}

.offers-params-item {
  flex: 1;
  text-align: left;
  display: flex;
  flex-direction: column;
  position: relative;
}

.offers-params-label {
  padding-left: 3px;
  padding-bottom: 10px;
  min-height: 32px;
}

.offers-params-item input {
  width: 100%;
  padding: 10px 25px;
  padding-left: 35px;
  font-size: 16px;
  border: 1px solid var(--gray-20);
  border-radius: 10px;
}

.currency-tag {
  font-weight: 700;
  font-size: 18px;
  position: absolute;
  top: 41px;
  left: 14px;
}

input[type='number'] {
  -moz-appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Table Holder */
.offers-table-holder {
  text-align: center;
  width: 100%;
  background: var(--white);
  box-shadow: var(--paper-shadow);
  border-radius: 10px;
}

table {
  width: 100%;
}

table th {
  padding: 10px 0;
}

table tr {
  border-bottom: 1px solid var(--gray-20);
}

table td {
  padding: 14px 10px;
}

.isBestOffer {
  background: var(--blue-00);
  border: 2px solid var(--blue-50);
}

.bestOfferTag {
  width: 32px;
}

.table-buttons {
  width: 100%;
  display: grid;
  place-items: center;
}

.btn-apply {
  background: var(--blue-50);
  color: var(--white);
  border: none;
  border-radius: 10px;
  padding: 5px 14px;
  margin-left: 12px;
  cursor: pointer;
  font-weight: 600;
}

.btn-apply .btn-spinner {
  border: none !important;
  border-radius: 50% !important;
}

.lenderLogo {
  width: 62px;
}

/* Mobile Offers */
.offers-modal-overlay {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: grid;
  place-items: center;
  padding: 30px 15px;
  overflow-y: auto;
}

.offers-modal-content {
  width: 100%;
  max-width: 700px;
  background: var(--white);
  padding: 30px 15px;
  box-shadow: var(--card-shadow);
  border-radius: 10px;
  text-align: center;
  margin-bottom: 30px;
  position: relative;
}

.offers-modal-content p.congrats {
  font-size: 1rem;
}

.mobile-tile-header {
  max-width: 90%;
  margin: auto;
  border-bottom: 1px solid var(--gray-20);
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
}

.modal-best-offer {
  position: absolute;
  top: 0px;
  left: -30px;
}

.mobileLenderLogo {
  height: 62px;
  margin-bottom: 15px;
}

.mobile-title-content {
  max-width: 90%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.mobile-title-content .offer-slab {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--gray-20);
}

.mobile-title-content .offer-slab:last-child,
.mobile-title-content .offer-slab:last-child(1) {
  border-bottom: none;
}

.btn-modal-close {
  width: 40px;
  height: 40px;
  background-image: url(../assets/modal-close.svg);
  background-size: 40px;
  background-repeat: no-repeat;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.modal-button-holder {
  display: grid;
  place-items: center;
  padding-top: 30px;
}

.modal-learn-link {
  display: grid;
  place-items: center;
  padding: 20px 0;
}

.modal-learn-link a {
  display: table;
  font-weight: 700;
  color: initial;
  text-decoration: underline;
}

.btn-modal-act-btn {
  width: 100%;
  max-width: 200px;
  padding: 14px;
  background-color: var(--blue-50);
  color: var(--white);
  font-weight: 700;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  text-transform: uppercase;
}

/* Mobile Offer Card */
.mobile-offer-card {
  border: 1px solid #000;
  border-radius: 10px;
  text-align: center;
  overflow: hidden;
  margin-bottom: 20px;
}

.toggle-mobile-best-offer {
  border-width: 3px;
}

.mobile-best-offer {
  background: #000;
  color: var(--white);
  padding: 5px;
}

.mobile-offer-header {
  width: 80%;
  margin: auto;
  padding-top: 15px;
  border-bottom: 1px solid var(--gray-20);
  margin-bottom: 15px;
}

.mobile-lender-name {
  font-size: 16px;
  padding: 10px;
}

.mobile-offers-content {
  width: 70%;
  margin: auto;
}

.mobile-offer-card .offer-slab {
  padding-bottom: 15px;
  border-bottom: 1px solid var(--gray-20);
  margin-bottom: 15px;
}

.more-info-trigger,
.hide-info-trigger {
  display: table;
  margin: auto;
  font-size: 16px;
  font-weight: bold;
  padding-right: 30px;
  background-image: url(../assets/more-caret.svg);
  background-position: right;
  background-repeat: no-repeat;
  border-bottom: 1px solid #000;
  margin-bottom: 20px;
  cursor: pointer;
}

.hide-info-trigger {
  background-image: url(../assets/hide-caret.svg);
}

.mobile-offers-collapsible {
  height: 100%;
  max-height: 100%;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
}

.toggle-height {
  max-height: 0;
}

.mobile-offers-holder {
  display: none;
}

/* END OF OFFERS TABLE */
@media screen and (max-width: 999px) {
  .mobile-offers-holder {
    display: block;
  }

  .offers-table-holder {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .offers-params-form {
    flex-direction: column;
  }

  .offer-buttons {
    max-width: 285px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
}
