.header-title {
  margin-bottom: 24px;
}

.info-box,
.mobile-logout {
  display: flex;
  width: 100%;
  height: 88px;
  border-radius: 10px;
  background: var(--white);
  margin-bottom: 24px;
  box-shadow: var(--paper-shadow);
}

.mobile-logout {
  display: none;
  padding: 18px 36px;
  align-items: center;
  padding-left: 70px;
  background-image: url(../assets/door-arrow-left.svg);
  background-size: 40px;
  background-repeat: no-repeat;
  background-position: 20px;
  margin-top: 19px;
}

.info-box-content {
  padding: 18px 36px;
  display: flex;
  flex: 1;
  align-items: center;
}

.left-side {
  flex: 1;
}

.info-disabled {
  color: var(--gray-40);
}

.buttonDisabled {
  display: none;
}

.change-btn {
  width: 106px;
  height: 36px;
  padding: 6px 16px;
  border-radius: 10px;
  border: 1px solid var(--gray-40);
  background: var(--white);
}
.change-btn:hover {
  background: var(--gray-00);
}

.settings-modal-title {
  display: block;
}

.settings-modal-title-mobile {
  display: none;
}

.settings-input-container .input-wrapper {
  max-width: 100%;
}

/* ===Modals content Styling=== */
.line {
  border: 1px solid #e1e4e8;
  margin-top: 16px;
}
.hidden {
  display: none;
}
/* ===todo mobile code here === */

@media screen and (max-width: 699px) {
  .info-box {
    height: 64px;
  }
  .info-box-content {
    padding: 12px 16px;
  }

  .info-box-content .SubTitle {
    font-size: 14px;
  }

  .mobile-logout {
    display: flex;
    height: 64px;
    margin-top: 43px;
  }

  .settings-modal-title {
    display: none;
  }

  .settings-modal-title-mobile {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 20%;
  }

  .settings-input-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .password-inputs {
    margin-top: 24px !important;
  }
  .change-btn {
    width: 74px;
    height: 32px;
    padding: unset;
  }
  .info-box-title :nth-child(1) {
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4000000059604645px;
  }
  .change-btn :nth-child(1) {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.25px;
  }
  .hidden {
    display: block;
  }
}
