/* notification badge */
/* w = h = 14px */

.app-navbar {
  display: flex;
  justify-content: space-between;
}

.navbar-list {
  gap: 16px;
  align-items: center;
}

/* notification */
.navbar-notify-holder,
.navbar-notification {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--white);
  border: 1px solid var(--gray-10);
  background-image: url(../assets/notification-bell.svg);
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  position: relative;
}

.navbar-user-profile {
  position: relative;
}

.navbar-profile {
  gap: 10px;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.navbar-avatar {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--blue-50);
  color: var(--white);
  overflow: hidden;
  display: grid;
  place-items: center;
}

.navbar-avatar img {
  height: 100%;
  object-fit: cover;
}

.navbar-caret {
  transition: 0.2s ease-out;
  -webkit-transition: 0.2s ease-out;
}

.rotate-180 {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

.navbar-caret img {
  width: 18px;
  height: 18px;
  object-fit: contain;
}

.navbar-logout {
  width: 160px;
  box-shadow: var(--card-shadow);
  border-radius: 10px;
  position: absolute;
  top: 50px;
  right: 0;
  background-color: var(--white);
  text-align: right;
  padding: 10px;
  padding-right: 50px;
  color: var(--red-40);
  background-image: url(../assets/door-arrow-left.svg);
  background-repeat: no-repeat;
  background-position: 90%;
  background-size: 24px;
  cursor: pointer;
}
