.sign-up-container {
  height: 100vh;
  background: var(--white);
  overflow-y: auto;
  padding-bottom: 30px;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
}

/* Sign Up Form */
.sign-up-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sign-up-form-content {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
}

.sign-up-form-terms {
  display: flex;
  flex-direction: column;
  margin-top: 34px;
}

.ant-checkbox {
  margin-top: 10px;
}

.ant-checkbox-wrapper+.ant-checkbox-wrapper {
  margin-left: unset;
}

.ant-checkbox-wrapper {
  align-items: center;
}

.ant-checkbox-inner {
  width: 28px;
  height: 28px;
  border-radius: 6px;
}

.ant-checkbox+span {
  padding-top: 18px;
}

/* End Sign Up Form */

/* Sign Up Step Change Button */
.change-step-btn {
  position: absolute;
  top: 9rem;
  left: 1rem;
}

@media screen and (min-width: 768px) {
  .change-step-btn {
    left: 100px;
  }
}

.change-step-btn button {
  width: 1rem;
  height: 1rem;
  background: var(--white);
  border: none;
  background-image: url(assets/carret-left.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  cursor: pointer;
  background-size: contain;
}

@media screen and (min-width: 768px) {
  .change-step-btn button {
    width: 56px;
    height: 56px;
    border: 1px solid var(--gray-20);
    border-radius: 10px;
    background-size: 10px;
  }
}

.sign-up-form-btn {
  margin-top: 58px;
  width: 100%;
  text-align: center;
}

.sign-up-form-btn button {
  margin: 0 auto;
  max-width: 9.625rem;
}

.sign-up-form-btn button span {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.15px;
}

/* End Sign Up Step Change Button */