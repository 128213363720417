/* === OFFER SLAB === */
.offer-slab {
  text-align: center;
}

.offer-label {
  gap: 7px;
  -webkit-gap: 7px;
  justify-content: center;
}

.offer-label img {
  width: 13px;
  object-fit: contain;
}

.offer-value {
  display: flex;
  gap: 5px;
  justify-content: center;
}

.offer-value {
  flex: 1;
}

.offer-value img {
  display: none;
  width: 16px;
  object-fit: contain;
}

/* === END OF OFFER SLAB === */

/* === APP BUTTON === */
.light-blue-btn {
  padding: 6px 18px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  color: var(--blue-60);
  background-color: var(--blue-00);
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  background-repeat: no-repeat;
  background-position: 10px;
  background-size: 17px;
  border: 1px solid var(--blue-00);
  text-align: center;
}

.light-blue-btn:hover {
  border: 1px solid var(--blue-10);
  background-color: var(--blue-10);
  box-shadow: var(--paper-shadow);
}

.border-btn {
  width: 100%;
  min-width: 124px;
  padding: 10px 18px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  color: var(--blue-60);
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 10px;
  background-size: 17px;
  border: 1px solid var(--blue-60);
}

.border-btn:hover {
  border: 1px solid var(--blue-60);
  color: var(--blue-60);
  box-shadow: var(--paper-shadow);
}

.disabled {
  background-color: var(--blue-10) !important;
  color: var(--blue-60);
  border: 1px solid var(--blue-10);
  cursor: not-allowed;
}

.disabled:hover {
  background-color: var(--blue-10) !important;
  color: var(--blue-60);
  border: 1px solid var(--blue-10);
}

/* === END OF APP BUTTON === */

/* === BEGIN OF QuestionSelection === */
.question-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.question-selection {
  width: 100%;
}

.question-title {
  margin-bottom: 48px;
}

.question-select-button {
  display: flex;
  align-items: center;
}

input[type='radio'] {
  position: fixed;
  margin-top: -16px;
  margin-left: 27px;
  opacity: 0;
}

.question-label-radio {
  width: 100%;
  max-width: 360px;
  height: 58px;
  border-radius: 10px;
  background: var(--white);
  display: flex;
  align-items: center;
  padding-left: 16px;
  border: 1px solid var(--gray-20);
  margin: 0 auto;
  margin-bottom: 16px;
  cursor: pointer;
}

.question-label-radio:hover {
  background: var(--gray-10);
}

.question-label-radio:hover:before {
  border: 1.3px solid var(--gray-60);
}

.question-label-radio:before {
  content: '';
  width: 30px;
  height: 30px;
  border: 1.3px solid var(--gray-20);
  border-radius: 50px;
  margin-right: 8px;
}

.question-label-radio::before+ :hover {
  border: 1.3px solid red;
}

/* input[type='radio']:checked + .question-label-radio {
  background: var(--blue-70) !important;
  color: var(--white);
} */

input[type='radio']:checked+.question-label-radio:before {
  width: 30px;
  height: 30px;
  border: 7px solid var(--white);
}

input[type='radio']:checked+.question-label-radio {
  background-color: var(--blue-60);
  color: var(--white);
}

.label-click {
  background: var(--blue-70) !important;
  color: var(--white);
  width: 360px;
  height: 58px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  margin-bottom: 16px;
}

.label-click:before {
  content: '';
  width: 30px;
  height: 30px;
  border: 7px solid var(--white);
  border-radius: 50px;
  margin-right: 8px;
}

/* === END OF QuestionSelection === */
/* === FLOATING ACTION BUTTON(FAB) === */
.fab-btn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--blue-60);
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  position: fixed;
  bottom: 12vh;
  right: 3vh;
  background-image: url(../assets/add-white.svg);
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
}

/* === END OF FLOATING ACTION BUTTON(FAB) === */

/* === BEGIN OF progress Bar Component === */

/* === END OF progress Bar Component === */

/* === APP ALERT === */
.app-alert {
  width: 95%;
  max-width: 350px;
  position: fixed;
  left: 2vw;
  bottom: 10vh;
  z-index: 3000;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 5px 32px rgba(0, 0, 0, 0.4);
  border: 1px solid var(--gray-20);
  padding: 15px 12px;
  gap: 10px;
  opacity: 0;
  transform: translateY(10px);
  -webkit-transform: translateY(10px);
  transition: 300ms all ease-in-out;
  -webkit-transition: 500ms all ease-in-out;
}

.app-alert-open {
  opacity: 1;
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
}

.app-alert-icon {
  flex-shrink: 1;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.app-alert-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.app-alert-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.app-alert-extras {
  font-size: 11px;
  justify-content: space-between;
}

.app-alert-close {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  box-shadow: var(--paper-shadow);
  background-color: var(--gray-30);
  background-image: url(../assets/close.png);
  background-size: 12px;
  background-position: center;
  background-repeat: no-repeat;
}

/* === END OF app ALERT === */

.border-less-btn {
  border: none;
  background: transparent;
  cursor: pointer;
  margin-top: 20px;
}

.remove-btn {
  background: #FFFFFF;
  border: 1px solid #D73A49;
  padding: 6px 16px;
  color: #D73A49;
  letter-spacing: -0.15px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  height: 36px;
  cursor: pointer;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

@media screen and (min-width: 768px) {
  .remove-btn {
    display: none;
  }
}

.remove-btn img {
  margin-right: 5px;
}

.remove-btn:hover {
  background: #CB2431;
  color: white;
}

.remove-btn:hover img {
  filter: brightness(0) invert(1);

}

.remove-btn:active {
  background: #B31D28;
}

/* === Handle inner inputs in other components === */
.inner-group-input {
  display: flex;
  flex-direction: row;
  gap: 12px;
  position: relative;
}

.inner-group-input .border-less-btn {
  position: absolute;
  right: 5%;
}

@media screen and (max-width: 768px) {
  .inner-group-input .border-less-btn {
    display: none;
  }
}

/* === CLEAN RADIO INPUT === */
.clean-radio-input {
  max-width: 360px;
  width: 100%;
  height: 58px;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
}

.clean-radio-input input {
  display: none;
}

.lbl-clean-radio {
  min-height: 58px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
  border: 1px solid var(--gray-20);
  position: relative;
  border-radius: 10px;
  padding: 15px;
  cursor: pointer;
}

.lbl-clean-radio .circle {
  width: 30px;
  height: 30px;
  border: 1px solid var(--gray-20);
  border-radius: 50%;
}

.lbl-clean-radio:hover {
  background: var(--gray-10);
}

.lbl-clean-radio:hover .circle {
  border-color: var(--gray-80);
}

.lbl-clean-radio:hover .circle.clicked-circle {
  border-color: #fff;
}

input[type='radio']:checked+.lbl-clean-radio {
  color: #fff !important;
  background: var(--blue-50) !important;
}

input[type='radio']:checked+.lbl-clean-radio>.circle {
  background: var(--blue-50);
  border: 5px solid #fff !important;
  border-radius: 50%;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.1);
}

/* === END OF CLEAN RADIO INPUT === */

/* === Select Input Modififcation === */
.ant-select-item-option {
  padding-bottom: 25px !important;
}

.ant-select-item-option:last-child {
  padding-bottom: 0px !important;
}

/* === END OF Select Input Modififcation === */