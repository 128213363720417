.journey-bg {
    padding: 5rem;
    display: flex;
    min-height: 1024px;
    font-family: 'Poppins', sans-serif;
}

.journey-bg-img {
    background-image: url(../assets/family-in-sand.svg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.big-step-banner-bg-img {
    background-image: url(../assets/big-step-banner.svg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.big-step-layout {
    width: 80%;
    border-radius: 1.3rem;
    opacity: 0.8;
    background: #1F2E44;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5rem;
    padding: 3rem;
    position: absolute;
    left: 10%;
    bottom: -20%;
}

.big-step-text {
    font-size: 2.5rem;
    text-align: center;
    font-weight: 700;
    letter-spacing: 0em;
    color: #F6F7F8;
    text-transform: uppercase;
}

.hassle-bg {
    background-image: url(../assets/access-half-logo.svg), url(../assets/hassle-bg.svg);
    background-position: 0% 40%, 100% 90%;
    background-size: auto;
    background-repeat: no-repeat;
}

.broker-two-bg {
    background-image: url(../assets/access-half-logo.svg), url(../assets/brokers-bg.svg);
    background-position: left center, center;
    background-size: auto, cover;
    background-repeat: no-repeat;

    background-color: rgba(0, 0, 24, .8);

    background-blend-mode: multiply;
}

.broker-bg {
    background-image: url(../assets/access-half-logo.svg), url(../assets/broker.svg);
    background-position: 0% 40%, right center;
    background-size: auto, 50% 50%;
    background-repeat: no-repeat;
    position: relative;
}

.bg-tint {
    background-color: rgba(20, 0, 0, .5);
    background-blend-mode: multiply;
}

.welcome-page_container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    justify-content: center;
    max-width: 1064px;
    margin-left: auto;
}

.journey-bg .logo {
    width: 316px;
    height: 82px;
    margin-left: auto;
}

.journey-bg button.start-btn {
    margin-left: auto;
    border: 2px solid #F42941;
    background-color: #F42941;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.5rem;
    letter-spacing: 0.095em;
    color: rgba(246, 247, 248, 1);
    text-transform: uppercase;
    padding: 1.5rem;
    border-radius: 22px;
    cursor: pointer;
}

.big-step-banner-bg-img button.start-btn.middle {
    margin-left: 0;
}

.journey-bg button.start-btn:hover {
    opacity: 0.9;
}

.red {
    color: #F42941;
}

.white {
    color: #F6F7F8;
}

.right-align {
    text-align: right;
}

.journey-bg .header {
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 4.6rem;
    letter-spacing: 0.095em;
    text-transform: uppercase;
}

.journey-bg .description,
.journey-bg .description p {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.6rem;
    letter-spacing: 0.04em;
}

.relative {
    display: flex;
    flex-direction: row-reverse;
}

p.repossessed-text {
    width: 50%;
    align-self: end;
}

.journey-bg .hassle-mortgage {
    max-width: 607px;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    justify-content: center;
}

.dark-blue-bg {
    background-color: rgba(31, 46, 68, 1) !important;
}

.eligibility-bg {
    background-image: url(../assets/eligibility-human.svg), url(../assets/access-red-bg.svg), url(../assets/dotted-lines.svg);
    background-position: 10px 50%, 120% 100%, 28% 31%;
    background-size: auto, 50% 80%, auto;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    padding-left: 12.5rem;
    padding-top: 10rem;
}

.journey-bg .eligibility-text {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.grey {
    color: rgba(31, 46, 68, 1);
}

.light-gold {
    color: rgba(115, 115, 115, 1);
}

.light-grey {
    color: rgba(115, 115, 115, 1);
}

.light-grey-bg {
    background: rgba(246, 247, 248, 1);
}

.eligibility-two-bg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
    max-width: 800px;
    margin-left: auto;
}

.eligibility-three-bg {
    max-width: 700px;
}

.eligibility-two-bg-img {
    background-image: url(../assets/red-book-bg.svg);
    background-position: 15% center;
    background-size: 20% 35%;
    background-repeat: no-repeat;
}

.eligibility-three-bg-img {
    background-image: url(../assets/timer-red-bg.svg), url(../assets/dotted-lines.svg);
    background-position: 15% center, 38% 21%;
    background-size: 20% 55%, auto;
    background-repeat: no-repeat;
}

.fca {
    width: 70%;
    max-width: 1024px;
    position: absolute;
    bottom: -11%;
    left: 15%;
    background-color: rgba(244, 41, 65, 0.8);
    margin: 0 auto;
    padding: 3.5rem;
    border-radius: 1.3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.fca img {
    width: 50%;
}

.fca-text {
    font-size: 3rem;
    font-weight: 700;
    line-height: 2.1rem;
}

.all-footer {
    min-height: 924px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 2rem;
    text-align: center;
    max-width: 51.5rem;
    margin: 0 auto;
}

.foot_er {
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-transform: uppercase;
    color: #9E9E9E;
}

.reviews {
    display: flex;
    flex-direction: column;
    padding: 3rem 0;
    gap: 2rem;
}

.reviews-header {
    font-size: 48px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0.095em;
    text-align: right;
    margin: 0 auto;
    text-transform: uppercase;
}

.reviews-box-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    padding: 3rem;
    max-width: 1200px;
    margin: 0 auto;
}

@media screen and (max-width: 1300px) {
    .relative {
        flex-direction: column;
        padding-top: 8rem;
    }

    p.repossessed-text {
        width: 100%;
        padding-top: 10rem;
    }

    .reviews-box-wrapper {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 800px) {
    .relative {
        flex-direction: column;
        padding-top: 20rem;
    }

    .journey-bg {
        padding: 2rem;
    }

    .journey-bg .header {
        font-size: 2.5rem;
        line-height: 3rem;
    }

    .journey-bg .description,
    .journey-bg .description p {
        font-size: 1rem;
        line-height: 1.35rem;
        letter-spacing: 0.04em;
    }

    .journey-bg .logo {
        width: 200px;
        margin-left: auto;
    }

    .hassle-bg {
        background-image: url(../assets/access-half-logo.svg);
        background-position: 0% 40%;
    }

    .eligibility-bg {
        background-image: url(../assets/access-red-bg.svg);
        background-position: bottom right;
        background-size: 50% 80%;
    }

    .eligibility-two-bg-img {
        background-position: top;
    }

    .eligibility-three-bg-img {
        background-position: top, 63% 50%;
        background-size: 20% 55%, auto;
    }

    .broker-bg {
        background-image: url(../assets/access-half-logo.svg);
    }

    .journey-bg button.start-btn {
        font-size: 1rem;
        line-height: 1.2rem;
        padding: 1rem;
        border-radius: 1rem;
    }

    .fca {
        width: 90%;
        flex-direction: column;
        left: 5%;
        padding: 1.5rem;
        border-radius: 1rem;
    }

    .fca-text {
        font-size: 2rem;
    }

    .fca img.trust-pilot-logo {
        width: 60%;
    }

    .big-step-text {
        font-size: 1.5rem;
    }

    .foot_er {
        font-size: 0.8rem;
    }

    .reviews-box-wrapper {
        padding: 1rem;
        grid-template-columns: repeat(1, 1fr);
        max-width: 80%;
    }
}

.review-box {
    background: #1F2E44;
    color: #9E9E9E;
    padding: 2rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.review-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #9E9E9E;
    color: #1F2E44;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
}

.review-profile {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.review-text {
    text-align: center;
}

.margin-bottom {
    margin-bottom: 10%;
}

.margin-bottom-1 {
    margin-bottom: 1rem;
}

.flex-column {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.flex-column.legal {
    padding-left: 15%;
    border-left: 1px solid #9E9E9E;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15%;
}

.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 15rem;
}

.uppercase {
    text-transform: uppercase;
}

.footer-links {
    color: #9E9E9E;
    font-size: 0.8rem;
}

.footer-question {
    font-size: 0.6rem;
}

.footer-number {
    font-size: 1.2rem;
    font-weight: bolder;
    color: #1F2E44;
}

.flex-middle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

img.pilot-stars {
    width: 15rem;
    z-index: 999;
}