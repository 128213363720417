.journey-header {
  width: 100%;
  padding: 15px;
  max-width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.journey-header img {
  height: 40px;
  width: 157px;
}

@media screen and (max-width: 599px) {
  .journey-header img {
    height: 30px;
    width: 117px;
  }
  .journey-header .navbar-profile-name {
    display: none;
  }
}
