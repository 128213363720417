/* === APPLICATION === */
.application-header {
  flex-direction: column;
  padding: 32px 0;
  gap: 12px;
}

.ant-progress-text {
  color: var(--blue-60) !important;
  font-weight: 600;
}

.application-control-bar {
  align-items: center;
  justify-content: space-between;
}

.app-btn {
  border: none;
  background-color: var(--blue-10);
  padding: 7px 17px;
  padding-left: 34px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 10px;
  color: var(--blue-60);
  border: 1px solid var(--blue-10);
  cursor: pointer;
  transition: 0.3s ease-out;
  -webkit-transition: 0.3s ease-out;
}

.app-btn:hover {
  border: 1px solid var(--gray-20);
  box-shadow: 0px 3px 12px var(--gray-20);
}

.btn-add {
  background-image: url(../assets/add-blue.svg);
  background-position: 10px;
  background-size: 16px;
  background-repeat: no-repeat;
}

/* .application-foot-header {
} */

.application-main-content {
  flex: 3;
}

.application-grid,
.application-details-grid {
  display: grid;
  row-gap: 24px;
  padding: 40px 0;
  grid-template-columns: repeat(2, 1fr);
}

.application-details-grid {
  grid-template-columns: repeat(4, 1fr);
}

.application-details-grid .offer-slab {
  text-align: left !important;
}

.application-grid .offer-label,
.application-grid .offer-value {
  justify-content: left;
}

/* Accordions */
.application-b2c-acc-box {
  padding: 30px 0;
  display: flex;
  gap: 20px;
  flex-direction: column;
}

/* Lender Icon */
.lender-icon {
  width: 100%;
  overflow: hidden;
}

.lender-icon img {
  width: 100%;
  object-fit: contain;
}

.application-diagram {
  flex: 1;
  padding-top: 50px;
  display: grid;
  place-items: center;
}

.is-application-complete {
  padding: 20px 0;
  color: var(--green-50);
}

.application-foot-header H6 {
  margin-bottom: 6px;
}

.application-paper-hero-text {
  display: table;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--gray-30);
}

.application-footer {
  padding: 20px 0;
}

/* APPLY PAGE */
.apply-header {
  margin-bottom: 20px;
}

.apply-back {
  max-width: 300px;
  gap: 20px;
  cursor: pointer;
  margin-bottom: 20px;
}

.apply-lender-paper {
  margin-bottom: 40px;
}

.apply-paper-header {
  text-align: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--gray-20);
}

.apply-lender-icon {
  height: 70px;
  margin-bottom: 12px;
}

.apply-lender-icon img {
  height: 100%;
  object-fit: contain;
}

.apply-btn-list {
  padding: 20px;
  justify-content: space-between;
}

/* APP INFO BOX */
.apply-infobox-holder {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.apply-infobox {
  justify-content: space-between;
}

.apply-infobox-left {
  gap: 20px;
  flex: 1;
}

.apply-infobox-left img {
  width: 24px;
}

.infobox-check {
  width: 32px;
  height: 32px;
}

.infobox-check img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.mobile-application-header {
  display: none;
}

.mobile-application,
.mobile-application-details {
  display: none;
}

.home-header {
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.home-header-desc {
  min-width: 550px;
}

/* === END OF APPLICATION === */

@media screen and (max-width: 1305px) {
  .application-details-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 999px) {
  .application-header * {
    transition: 0.3 ease;
    -webkit-transition: 0.3 ease;
  }

  .application-header {
    gap: 0;
  }

  .application-header H2 {
    font-size: 50px !important;
    transition: 0.3 ease;
    -webkit-transition: 0.3 ease;
  }

  .application-control-bar * {
    font-size: 14px !important;
  }

  .application-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 699px) {

  .desktop-application,
  .desktop-application-details {
    display: none;
  }

  .mobile-application,
  .mobile-application-details {
    display: block;
  }

  .mobile-desktop .offer-value {
    justify-content: right !important;
  }

  .home-header {
    display: none;
  }

  .mobile-application-header {
    display: block;
    margin-bottom: 20px;
  }

  .mobile-desktop {
    background-color: var(--white);
    padding: 18px 16px;
    box-shadow: var(--paper-shadow);
    border-radius: 10px;
    margin-bottom: 30px;
  }

  .mob-application-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--gray-20);
  }

  .mob-application-header img {
    max-width: 180px;
    width: 100%;
    object-fit: contain;
  }

  .mob-offer-values {
    padding: 14px 0;
  }

  .mobile-desktop .offer-slab {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .mobile-desktop .offer-value .H5 {
    color: var(--blue-60);
    font-size: 14px;
  }

  .mobile-desktop .offer-label {
    text-align: left;
  }

  .mobile-desktop .offer-label img {
    display: none;
  }

  .mobile-desktop .offer-value {
    align-items: center;
  }

  .mobile-desktop .offer-value img {
    display: block !important;
  }

  .mobile-desktop .apply-btn-list {
    flex-direction: column;
    gap: 20px;
    padding: 0;
    padding-top: 30px;
  }

  .apply-infobox-holder {
    background-color: var(--white-card);
    box-shadow: var(--paper-shadow);
    border-radius: 10px;
    gap: 3px;
  }

  .apply-infobox-holder .app-paper {
    box-shadow: none;
    padding: 14px;
  }

  .apply-infobox-holder .app-paper .H5 {
    font-size: 15px;
  }

  .apply-infobox-holder .app-paper .infobox-check {
    width: 20px;
  }

  .mob-blue-btn {
    width: 100%;
    background-color: var(--blue-60);
    color: var(--white);
    border-radius: 10px;
    padding: 14px;
    border: none;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .mob-blue-btn:disabled {
    opacity: 0.3;
    cursor: no-drop;
  }
}