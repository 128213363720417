.ant-modal-content {
  border-radius: 10px !important;
  background-color: var(--gray-10) !important;
  width: 468px !important;
}
.uploadModalheight .ant-modal-content {
  height: 407px;
  background-color: var(--white) !important;
}

.uploadModalheight .ant-modal-body {
  padding: 16px !important;
}

.ant-modal-body {
  background: var(--gray-10);
  border-radius: 10px !important;
  padding: 44px !important;
}
.isWhite .ant-modal-body {
  background: var(--gray-10);
}

.ant-modal-footer {
  background: var(--gray-10) !important;
  border-radius: 10px !important;
  padding-bottom: 50px !important;
  padding-right: 44px !important;
}
.ant-btn-lg {
  width: 169px !important;
}

.ant-btn-sm {
  width: 84px !important;
}
.ant-btn {
  height: 36px !important;
  border-radius: 10px !important;
  border: 1px solid var(--gray-40) !important;
}
.ant-btn:hover {
  background: var(--gray-00) !important;
  color: var(--gray-90) !important;
}

.ant-btn-primary {
  border: 0 !important;
  background: var(--blue-60) !important;
}

.ant-btn-primary:hover {
  background: var(--blue-60) !important;
  color: var(--white) !important;
}

.close-icon {
  display: none;
}
.btn-wrapper {
  display: none;
}

@media screen and (max-width: 699px) {
  .ant-modal {
    width: 100vw !important;
    height: 70vh;
    min-height: 500px;
    position: fixed !important;
    bottom: 0;
    /* left: 0; */
  }

  .ant-modal-body {
    width: 100vw !important;
    height: 70vh !important;
    position: fixed !important;
    bottom: 0;
  }

  .ant-modal-content {
    width: 100vw !important;
    height: 100vh !important;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
    border-radius: 20px 20px 0 0 !important;
  }

  .ant-modal-content::before {
    content: '';
    width: 70px;
    height: 7px;
    background: var(--gray-30);
    border-radius: 12px;
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    margin: auto;
    /* left: 80px; */
  }

  .isMobile .ant-modal-wrap {
    top: -107px;
  }

  .mobile-modal {
    position: relative;
  }

  .close-icon {
    display: block;
    position: absolute;
    top: 27px;
    left: 29px;
  }

  .isMobile .ant-modal-footer {
    display: none;
  }

  .btn-wrapper {
    display: flex;
    justify-content: center;
  }

  .btn-wrapper button {
    position: absolute;
    bottom: 24px;
  }

  .uploadModalheight .ant-modal-content {
    /* width: unset !important; */
    /* width: 100vw !important; */
    background: var(--gray-10) !important;
  }

  .uploadModalheight .ant-modal-body {
    padding-left: 10px;
    background: var(--gray-10) !important;
  }
  .uploadModalheight .modal-content {
    margin-top: 32px;
  }
}
