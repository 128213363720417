.ant-modal-body {
  background: var(--white);
  height: unset;
}

.ant-modal-close {
  top: 20px !important;
}

.modal-header {
  display: flex;
  justify-content: center;
  border-bottom: solid 1px var(--gray-20);
  margin: 0px -16px 0px -16px;
  padding-bottom: 14px;
}

.is-upload-modal-header {
  border: unset;
  padding-bottom: 22px;
}

.modal-upload-section {
  height: 407px;
  border: 1px dashed var(--gray-20);
  border-radius: 4px;
}

.modal-upload-section-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 22px;
}

.upload-icon-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 16%;
}
.upload-icon-section img {
  margin-bottom: 12px;
}

.upload-button-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10%;
}
.modal-btn {
  margin-top: 14px;
  display: block;
}

.modal-body-content {
  display: flex;
}

.modal-body {
  border-bottom: solid 1px var(--gray-20);
}

.doc-name-wrapper {
  flex: 1;
  display: flex;
  padding-bottom: 12px;
}

.doc-name-info {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
}
.doc-icon {
  margin-right: 20px;
  display: flex;
}

.doc-size {
  display: flex;
  justify-content: center;
  align-items: center;
}
.doc-size button {
  background: transparent;
  border: 1px solid var(--gray-90);
  width: 60px;
  height: 24px;
  border-radius: 6px;
}

.button-group {
  position: relative;
  top: 28px;
  right: -34%;
}
.is-error button {
  border-color: var(--red-60);
}
.is-error::before {
  content: '';
  width: 12px;
  height: 12px;
  background-image: url(assets/error-icn.svg);
  background-repeat: no-repeat;
  margin-right: 4px;
}
.upload-info-text-mobile {
  display: none;
}
.modal-title-mobile {
  display: none;
}
@media screen and (max-width: 699px) {
  .btn-mobile {
    width: 311px;
    height: 44px;
  }
  .modal-upload-section {
    border: 0;
  }
  .modal-btn {
    display: none;
  }
  .upload-info-text-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
  }
  .upload-icon-section {
    margin-top: 8%;
  }
  .doc-icon {
    justify-content: center;
    align-items: center;
  }
  .doc-icon img {
    height: 20px;
    width: 24px;
  }
  .modal-header {
    border-bottom: unset;
  }
  .uploadModalheight .ant-modal-body {
    padding: 10px !important;
    height: 500px;
  }
  .modal-body {
    border-bottom: unset;
    padding: 16px;
    background-color: var(--white);
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
  }
  .button-group {
    position: relative;
    top: 63px;
    right: -13%;
  }
  .doc-name-wrapper {
    padding-bottom: 0px;
  }
  .modal-title-mobile {
    display: block;
  }
  .modal-title-mobile:nth-child(1) {
    color: red;
  }
}
