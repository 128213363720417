/* accordion info box */
.accordion-info-box {
  background: var(--white);
  border-radius: 10px;
  border: 1px solid var(--blue-50);
}

.accordion-info-box-header {
  padding: 28px;
  display: flex;
  gap: 15px;
  justify-content: space-between;
  border-radius: 10px;
  box-shadow: var(--paper-shadow);
  cursor: pointer;
  position: relative;
}

.accordion-info-box-header .SubTitle {
  pointer-events: none;
}

.accordion-info-box-header .left,
.accordion-info-box-header .right {
  display: flex;
  gap: 15px;
  align-items: center;
}

.accordion-info-box-header .right .ant-btn-primary {
  font-weight: 700 !important;
  font-size: 14px !important;
  padding: 5px 15px !important;
  border-radius: 20px !important;
}

.accordion-info-box-header .right .in-progress {
  background: var(--gray-50) !important;
  color: var(--white) !important;
}

.accordion-info-box-header .right .complete-btn {
  background: var(--green-50) !important;
  color: var(--white) !important;
}

.accordion-info-box-header .right img {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
}

.rotate-180-deg {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

.gray-out {
  filter: grayscale(50);
  -webkit-filter: grayscale(50);
}

.accordion-info-box-content {
  overflow: hidden;
  height: 100%;
  max-height: 0;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  opacity: 0;
}

.open-accordion {
  opacity: 1;
  padding: 24px 70px;
  max-height: 500px;
  overflow-y: auto;
}

.accordion-content-item {
  display: flex;
  gap: 15px;
  padding: 20px 0;
  border-bottom: 1px solid var(--gray-30);
}

.accordion-content-item:last-child {
  border: none;
}

.accordion-content-item .left {
  flex: 3;
  opacity: 0.7;
}

.accordion-content-item .right {
  flex: 2;
  gap: 15px;
  justify-content: right;
}

.accordion-content-item .right .Bold {
  text-align: right;
}

.accordion-content-item .right .btn-edit {
  height: 24px;
  cursor: pointer;
}